import styled, { css } from "styled-components"
import { StyledBasicText, StyledBasicTitle } from "../../styles/BasicStyles"
import { AppContainer, AppSection, mediaDown } from "../../styles/Mixins"
import ThemeStyle from "../../styles/ThemeStyle"

export const PolicyPrivacy = styled.div`
  ${AppSection};
`

export const Wrapper = styled.div`
  ${AppContainer};
  padding-top: 5rem;
  padding-bottom: 5rem;

  * {
    font-size: clamp(1rem, 0.88rem + 0.6000000000000001vw, 1.6rem);
  }
  table {
    margin-bottom: 5rem;
  }

  th {
    background-color: #c1b9bb;
    border: 1px solid black;
    padding: 1rem;
  }

  td {
    background-color: white;
    border: 1px solid black;
    padding: 1rem;
    font-weight: normal;
  }

  ol {
    list-style: decimal;
    padding-left: 3rem;

    li {
      list-style-position: inside;

      font-weight: normal;
      margin-bottom: 2rem;
    }
  }

  ul {
    padding-left: 3rem;
    list-style: circle;
    font-weight: bold;
    li {
      padding-left: 3rem;

      font-weight: normal;
      margin-bottom: 2rem;
    }
  }
  overflow-x: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  &::-webkit-scrollbar {
    display: none;
  }
`

export const Title = styled.h2`
  ${StyledBasicTitle};
  font-size: 3rem;
  font-weight: 500;
  margin-top: 10rem;
`
export const HeadTitle = styled.h1`
  ${StyledBasicTitle};
  font-size: 4rem;
  font-weight: bold;
  margin-bottom: 3rem;
`
export const Strong = styled.p`
  ${StyledBasicText};
  font-weight: bold;
  display: inline-block;
`

export const Link = styled.a`
  cursor: none;
  transition: ${ThemeStyle.transition};
  width: 100%;
  &:hover {
    color: ${ThemeStyle.colors.main};
  }
`
export const TextWrapper = styled.div`
  margin: 3rem 0 3rem 0;
`

export const Text = styled.p`
  ${StyledBasicText};
  line-height: 2rem;
`

export const Underline = styled.p`
  text-decoration: underline;
  display: inline-block;
`

export const ButtonWrapper = styled.a`
  position: fixed;
  top: 90%;
  left: 90%;

  ${mediaDown(
    ThemeStyle.breakpoints.md,
    css`
      left: 70%;
    `
  )}
`
