import React, { FunctionComponent } from "react"
import CursorContextProvider from "../components/CustomCursor/context/CursorContextProvider"
import MainLayout from "../layouts/MainLayout/MainLayout"
import PrivacyPolicy from "../components/PrivacyPolicy/PrivacyPolicy"

type Props = {}

const PrivacyPolicyPage: FunctionComponent<Props> = props => {
  return (
    <CursorContextProvider>
      <MainLayout navigation={false}>
        <PrivacyPolicy />
      </MainLayout>
    </CursorContextProvider>
  )
}

export default PrivacyPolicyPage
