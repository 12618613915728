import React, { FunctionComponent, useContext } from "react"
import * as Styled from "./PrivacyPolicy.styled"
import CursorContext from "../CustomCursor/context/CursorContext"
import Button from "../Button"

type Props = {}

const PrivacyPolicy: FunctionComponent<Props> = props => {
  const { events } = useContext(CursorContext)

  return (
    <Styled.PolicyPrivacy>
      <Styled.Wrapper>
        <Styled.HeadTitle>
          Polityka prywatności ITENERUM Sp. z o.o.
        </Styled.HeadTitle>
        <Styled.ButtonWrapper {...events} href={"/"}>
          <Button style={{ padding: "1rem 2rem", fontSize: "1rem" }}>
            Strona główna
          </Button>
        </Styled.ButtonWrapper>
        <Styled.Title>INFORMACJE WSTĘPNE</Styled.Title>
        <Styled.TextWrapper>
          <Styled.Text>
            Szanując prawo do prywatności,{" "}
            <Styled.Strong>ITENERUM Sp. z o.o. z siedzibą</Styled.Strong> w
            Warszawie (04-767), ul. Patriotów 303 (dalej: „Administrator”),
            przetwarza Państwa dane osobowe zgodnie z krajowymi i europejskimi
            przepisami prawa.
          </Styled.Text>
        </Styled.TextWrapper>
        <Styled.TextWrapper>
          <Styled.Text>
            Administrator zapewnia bezpieczeństwo danych osobowych, czyli ich
            poufność, dostępność, integralność, a także rozliczalność
            prowadzonych przez siebie działań.{" "}
          </Styled.Text>
        </Styled.TextWrapper>
        <Styled.TextWrapper>
          <Styled.Text>
            Aby nasze procesy przetwarzania były dla Państwa przejrzyste, w
            ramach niniejszej polityki prywatności przedstawiamy najważniejsze
            informacje dotyczące przetwarzania przez Administratora danych
            osobowych na gruncie Rozporządzenia Parlamentu Europejskiego i Rady
            (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób
            fizycznych w związku z przetwarzaniem danych osobowych i w sprawie
            swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE
            (ogólne rozporządzenie o ochronie danych, dalej: „RODO”).{" "}
          </Styled.Text>
        </Styled.TextWrapper>

        <Styled.Title>ADMINISTRATOR DANYCH</Styled.Title>
        <Styled.TextWrapper>
          <Styled.Text>
            Administratorem, czyli podmiotem decydującym o celach i środkach
            przetwarzania danych osobowych, jest{" "}
            <Styled.Strong>
              ITENERUM Sp. z o.o. z siedzibą w Warszawie 04-767, ul. Patriotów
              303.
            </Styled.Strong>{" "}
          </Styled.Text>
        </Styled.TextWrapper>

        <Styled.Title>POZYSKANIE DANYCH I CEL ICH PRZETWARZANIA</Styled.Title>
        <Styled.TextWrapper>
          <Styled.Text>
            W zależności od Państwa relacji z nami, realizując swoje funkcje
            biznesowe przetwarzamy dane osobowe w różnych celach i zakresie:
          </Styled.Text>
        </Styled.TextWrapper>
        <Styled.TextWrapper>
          <ol type={"1"} style={{ fontWeight: "bold" }}>
            <li>Użytkownicy witryny SERIOIT.PL. </li>
            <div>
              <table>
                <tr>
                  <th>Cel przetwarzania i podstawa prawna</th>
                  <th>Okres przechowywania danych</th>
                  <th>
                    W przypadku zbierania danych w sposób inny niż od Państwa -
                    źródło ich pochodzenia i kategorie danych osobowych
                  </th>
                </tr>
                <tr>
                  <td>
                    Użytkownicy strony nie mają obowiązku podawania swoiich
                    danych osobowych, aby móc skorzystać z Witryny serioit.pl.
                    Jeśli jednak chcą Państwo skorzystać z jej funkcjonalności,
                    przetwarzamy wówczas dane, które są niezbędne dla
                    przygotowania dla Państwa oferty subskrypcji obsługi IT, a w
                    razie jej akceptacji – dane niezbędne do zawarcia i
                    wykonania umowy. Podstawą przetwarzania jest wówczas art. 6
                    ust. 1 lit. b RODO, a więc niezbędność do wykonania umowy,
                    której stroną jest osoba, której dane dotyczą, lub do
                    podjęcia działań na żądanie osoby, której dane dotyczą przed
                    zawarciem umowy. Natomiast w stosunku do osób działających w
                    imieniu lub na rzecz Klienta podstawą przetwarzania będzie
                    art. 6 ust. 1 lit. f RODO, tj. uzasadniony interes
                    administratora, jakim jest zawarcie umowy.
                    <br></br>
                    <br></br>
                    Dane przetwarzane będą również dla celu realizacji
                    obowiązków wynikających z przepisów Prawa
                    telekomunikacyjnego i ustawy o świadczeniu usług drogą
                    elektoniczną (art. 6 ust. 1 lit. c RODO).
                    <br></br>
                    <br></br>
                    Jeśli ma to zastosowanie dane będą przetwarzane także w celu
                    prowadzenia rozliczeń, księgowości i sprawozdawczości
                    finansowej (art. 6 ust. 1 lit. c i f RODO).
                    <br></br>
                    <br></br>
                    Państwa dane mogą być przetwarzane także w związku z
                    realizacją pozostałych prawnie uzasadnionych interesów
                    administratora (art. 6 ust. 1 lit. f RODO):
                    <br></br>
                    <br></br>- w celu ustalenia, dochodzenia i obrony przed
                    roszczeniami,
                    <br></br>
                    <br></br>- w celach statystycznych, związanych z poprawą
                    efektywności pracy, jakości świadczonych usług i
                    dostosowaniem ich do odbiorców.
                  </td>
                  <td>
                    Jeśli w wyniku przedstawionej przez nas oferty dojdzie do
                    zawarcia umowy, Państwa dane osobowe będą przechowywane
                    przez czas trwania zawartej umowy, a po jej zakończeniu
                    przez okres wymagany przepisami prawa lub do czasu upływu
                    terminów przedawnienia roszczeń z nią związanych.
                    <br></br>
                    <br></br>
                    Jeśli po przedstawionej Państwu ofercie nie dojdzie do
                    zawarcia umowy, Państwa dane będą przechowywane przez okres
                    1 miesiąca od dnia przedstawienia Państwu oferty, a w
                    przypadku wyrażenia zgody na przetwarzanie Państwa danych do
                    celów przesyłania Państwu ofert naszych usług – do czasu
                    wycofania zgody lub wniesienia sprzeciwu, tj. okazania nam w
                    dowolny sposób, że nie życzą sobie Państwo pozostawać z nami
                    w kontakcie i otrzymywać informacji o podejmowanych przez
                    nas działaniach.
                  </td>
                  <td>
                    Jeśli danych osobowych nie pozyskaliśmy bezpośrednio od
                    Państwa, to ich źródłem jest Państwa Pracodawca/Kontahent,
                    który przekazał nam Państwa dane na potrzeby
                    zawarcia/realizacji umowy. W tym przypadku, pozyskane dane
                    osobowe obejmują dane niezbędne do zawarcia i realizacji
                    umowy (typowo są to imię, nazwisko, adres e-mail, nr
                    telefonu i/lub adres korespondencyjny, miejsce pracy,
                    stanowisko służbowe).
                  </td>
                </tr>
              </table>
            </div>
            <li>
              Subskrybenci biuletynu informacyjnego i adresaci działań
              marketingowych
            </li>
            <div>
              <table>
                <tr>
                  <th>Cel przetwarzania i podstawa prawna</th>
                  <th>Okres przechowywania danych</th>
                  <th>
                    W przypadku zbierania danych w sposób inny niż od Państwa -
                    źródło ich pochodzenia i kategorie danych osobowych
                  </th>
                </tr>
                <tr>
                  <td>
                    Realizacja prawnie uzasadnionego interesu Administratora
                    (art. 6 ust. 1 lit. f RODO), polegającego na marketingu
                    własnych produktów i usług, a w przypadku wyraźnej zgody –
                    także produktów i usług firm współpracujących z
                    Administratorem (art. 6 ust. 1 lit. a RODO), przy
                    wykorzystaniu następujących form komunikacji:
                    <br></br>
                    <br></br>- newsletter (wysyłka biuletynu informacyjnego),
                    <br></br>
                    <br></br>- rozmowy głosowe (marketing telefoniczny),
                    <br></br>
                    <br></br>- przesyłanie informacji handlowej (wiadomości
                    e-mail),
                    <br></br>
                    <br></br>- przesyłanie informacji handlowej (wysyłka sms),
                    <br></br>
                    <br></br>- wysyłki materiałów promocyjnych pocztą
                    tradycyjną.
                    <br></br>
                    <br></br>Cele wskazane w treści zgód na przetwarzanie danych
                    osobowych - jeśli takie zgody były wyrażane (art. 6 ust. 1
                    lit. a RODO),
                    <br></br>
                    <br></br>Cel w postaci realizacji obowiązków wynikających z
                    przepisów prawa, w szczególności Prawa telekomunikacyjnego i
                    ustawy o świadczeniu usług drogą elektroniczną (art. 6 ust.
                    1 lit. c RODO).
                    <br></br>
                    <br></br>Dane przetwarzane są także w związku z realizacją
                    pozostałych prawnie uzasadnionych interesów administratora
                    (art. 6 ust. 1 lit. f RODO):
                    <br></br>- w celu ustalenia, dochodzenia i obrony roszczeń,
                    <br></br>
                    <br></br>- w celach statystycznych, związanych z poprawą
                    efektywności pracy, jakości świadczonych usług i
                    dostosowaniem ich do odbiorców.
                  </td>
                  <td>
                    Państwa dane osobowe będą przechowywane do czasu wycofania
                    zgody lub wniesienia sprzeciwu, tj. okazania nam w dowolny
                    sposób, że nie życzą sobie Państwo pozostawać z nami w
                    kontakcie i otrzymywać informacji o podejmowanych przez nas
                    działaniach. Po wycofaniu zgody lub wyrażeniu sprzeciwu,
                    dane osobowe mogą być przechowywane na potrzeby wykazywania
                    prawidłowości spełnienia obowiązków prawnych spoczywających
                    na Administratorze i związanych z nimi roszczeniami.
                  </td>
                  <td>
                    Jeśli danych osobowych nie pozyskaliśmy bezpośrednio od
                    Państwa, to ich źródłem jest podmiot, który dysponował
                    wyrażoną przez Panią/Pana zgodą na ich udostępnienie na
                    rzecz Administratora, lub inną ważną podstawą prawną. W tym
                    przypadku, pozyskane dane osobowe obejmują dane niezbędne do
                    prowadzenia danego rodzaju działań marketingowych (typowo są
                    to imię, nazwisko, adres e-mail, nr telefonu i/lub adres
                    korespondencyjny).
                  </td>
                </tr>
              </table>
            </div>

            <li>Klienci i osoby wyznaczone do realizacji umowy</li>
            <div>
              <table>
                <tr>
                  <th>Cel przetwarzania i podstawa prawna</th>
                  <th>Okres przechowywania danych</th>
                  <th>
                    W przypadku zbierania danych w sposób inny niż od Państwa -
                    źródło ich pochodzenia i kategorie danych osobowych
                  </th>
                </tr>
                <tr>
                  <td>
                    W celu realizacji umowy z Klientem lub podjęcia działań
                    przed zawarciem umowy, na żądanie Klienta, wyrażone w
                    dowolny sposób, np. poprzez wypełnienie formularza
                    kontaktowego na stronie www (art. 6 ust. 1 lit. b RODO –
                    jeśli jesteś Klientem; art. 6 ust. 1 lit. f RODO – jeśli
                    jesteś osobą działającą w imieniu lub na rzecz Klienta),
                    <br></br>
                    <br></br>w celu prowadzenia rozliczeń, księgowości i
                    sprawozdawczości finansowej (art. 6 ust. 1 lit. c oraz f
                    RODO),
                    <br></br>
                    <br></br>w celu realizacji obowiązków wynikających z
                    przepisów prawa (art. 6 ust. 1 lit. c RODO),
                    <br></br>
                    <br></br>w celach wskazanych w treści zgód na przetwarzanie
                    danych osobowych - jeśli takie zgody były wyrażane (art. 6
                    ust. 1 lit. a RODO). Uwaga: udostępnienie innych danych niż
                    żądane przez Administratora lub danych oznaczonych jako
                    opcjonalne, to wyraźne działanie równoznaczne z wyrażeniem
                    zgody na przetwarzanie danych osobowych w celach, w jakich
                    zostały udostępnione.
                    <br></br>
                    <br></br>Dane mogą być przetwarzane także w związku z
                    realizacją prawnie uzasadnionych interesów administratora
                    (art. 6 ust. 1 lit. f RODO):
                    <br></br>
                    <br></br>- w celu ustalenia, dochodzenia i obrony przed
                    roszczeniami,
                    <br></br>
                    <br></br>- w celach statystycznych, związanych z poprawą
                    efektywności pracy, jakości świadczonych usług i
                    dostosowaniem ich do odbiorców.
                  </td>
                  <td>
                    Państwa dane osobowe będą przechowywane do czasu upływu
                    terminów przedawnienia roszczeń wynikających z umowy z
                    Klientem.
                  </td>
                  <td>
                    Dane osobowe zostały pozyskane od Państwa, od Klienta lub od
                    osoby współpracującej z nami w imieniu Klienta. Dane osobowe
                    pozyskujemy w zakresie niezbędnym do zawarcia i realizacji
                    umowy, przy czym najczęściej są to: imię, nazwisko, adres
                    pocztowy, adres e-mail, nr telefonu, dane dotyczące
                    zamówienia oraz dane do faktury. W przypadku zawarcia umowy
                    w formie pisemnej, przetwarzamy także nr PESEL lub nr dowodu
                    osobistego. W przypadku elektronicznej obsługi transakcji –
                    także login, hasło oraz historie zakupów i aktywności.
                  </td>
                </tr>
              </table>
            </div>
            <li>
              Kontrahenci, dostawcy i osoby wyznaczone do realizacji umowy
            </li>
            <div>
              <table>
                <tr>
                  <th>Cel przetwarzania i podstawa prawna</th>
                  <th>Okres przechowywania danych</th>
                  <th>
                    W przypadku zbierania danych w sposób inny niż od Państwa -
                    źródło ich pochodzenia i kategorie danych osobowych
                  </th>
                </tr>
                <tr>
                  <td>
                    W celu realizacji umowy z Kontrahentem lub podjęcia działań
                    przed zawarciem umowy, na żądanie Kontrahenta (art. 6 ust. 1
                    lit. b RODO – jeśli jesteś Kontrahentem; art. 6 ust. 1 lit.
                    f RODO – jeśli jesteś osobą działającą w imieniu lub na
                    rzecz Kontrahenta),
                    <br></br>
                    <br></br>w celu prowadzenia rozliczeń, księgowości i
                    sprawozdawczości finansowej (art. 6 ust. 1 lit. c i f
                    RODO).,
                    <br></br>
                    <br></br>w celu realizacji innych obowiązków wynikających z
                    przepisów prawa (art. 6 ust. 1 lit. c RODO).
                    <br></br>
                    <br></br>Dane mogą być przetwarzane także w związku z
                    realizacją pozostałych prawnie uzasadnionych interesów
                    administratora (art. 6 ust. 1 lit. f RODO):
                    <br></br>
                    <br></br>- w celu ustalenia, dochodzenia i obrony przed
                    roszczeniami,
                    <br></br>
                    <br></br>- w celach statystycznych, związanych z poprawą
                    efektywności pracy, jakości świadczonych usług i
                    dostosowaniem ich do odbiorców.
                  </td>
                  <td>
                    Państwa dane osobowe będą przechowywane przez okres wymagany
                    przepisami prawa lub do czasu upływu terminów przedawnienia
                    roszczeń wynikających z umowy z Kontrahentem – w zależności,
                    który okres jest dłuższy.
                  </td>
                  <td>
                    Co do zasady, przetwarzamy dane podawane przez Państwa.
                    Jeśli nie podawali nam Państwo swoich danych, to
                    pozyskaliśmy je od naszego Kontrahenta lub od osoby
                    działającej w imieniu lub na rzecz naszego Kontrahenta. Dane
                    osobowe pozyskujemy w zakresie niezbędnym do zawarcia i
                    realizacji umowy, przy czym najczęściej są to: imię,
                    nazwisko, adres pocztowy, adres e-mail, nr telefonu, dane
                    dotyczące zamówienia oraz dane do faktury. W przypadku
                    zawarcia umowy w formie pisemnej, przetwarzamy także nr
                    PESEL lub nr dowodu osobistego. W przypadku współpracy w
                    systemie informatycznym – także login, hasło oraz historię
                    współpracy.
                  </td>
                </tr>
              </table>
            </div>

            <li>
              Wnioskujący, osoby składające reklamacje i ich przedstawiciele
            </li>
            <div>
              <table>
                <tr>
                  <th>Cel przetwarzania i podstawa prawna</th>
                  <th>Okres przechowywania danych</th>
                  <th>
                    W przypadku zbierania danych w sposób inny niż od Państwa -
                    źródło ich pochodzenia i kategorie danych osobowych
                  </th>
                </tr>
                <tr>
                  <td>
                    W celu rozpatrzenia złożonej skargi, wniosku lub reklamacji
                    (art. 6 ust. 1 lit. f RODO),
                    <br></br>
                    <br></br>w celu realizacji umowy powiązanej ze zgłoszeniem
                    (art. 6 ust. 1 lit. b RODO – jeśli jesteś stroną umowy; art.
                    6 ust. 1 lit. f RODO – jeśli jesteś osobą współpracującą z
                    nami w imieniu naszego klienta lub kontrahenta),
                    <br></br>
                    <br></br>jeśli ma to zastosowanie - w celu prowadzenia
                    rozliczeń, księgowości i sprawozdawczości finansowej (art. 6
                    ust. 1 lit. c i f RODO),
                    <br></br>
                    <br></br>w celu realizacji pozostałych obowiązków
                    wynikających z przepisów prawa (art. 6 ust. 1 lit. c RODO),
                    <br></br>
                    <br></br>w celach określonych w treści zgody, o ile taka
                    została wyrażona (art. 6 ust. 1 lit. a RODO).
                    <br></br>
                    <br></br>Dane mogą być przetwarzane także w związku z
                    realizacją prawnie uzasadnionych interesów administratora
                    (art. 6 ust. 1 lit. f RODO):
                    <br></br>
                    <br></br>- w celu ustalenia, dochodzenia i obrony roszczeń,
                    <br></br>
                    <br></br>- w celach statystycznych, związanych z poprawą
                    efektywności pracy, jakości świadczonych usług i
                    dostosowaniem ich do odbiorców.
                    <br></br>
                    <br></br>UWAGA: jeśli opis okoliczności zdarzenia zawiera
                    dane szczególnej kategorii, to będą one przetwarzane w celu
                    ustalenia, dochodzenia i obrony przed roszczeniami (art. 9
                    ust. 2 lit. f RODO).
                  </td>
                  <td>
                    Państwa dane osobowe będą przechowywane do czasu realizacji
                    zgłoszenia oraz upływu terminów przedawnienia roszczeń.
                  </td>
                  <td>
                    Co do zasady, przetwarzamy dane podawane przez Państwa.
                    Jeśli nie podawali nam Państwo swoich danych, to ich źródłem
                    jest osoba, która wskazała Państwa dane w zgłoszeniu. Dane
                    osobowe przetwarzamy w zakresie wynikającym ze zgłoszenia,
                    przy czym najczęściej są to imię i nazwisko, adres e-mail,
                    telefon, adres korespondencyjny, okoliczności podlegające
                    zgłoszeniu.
                  </td>
                </tr>
              </table>
            </div>

            <li>Kandydaci do pracy lub współpracy</li>
            <div>
              <table>
                <tr>
                  <th>Cel przetwarzania i podstawa prawna</th>
                  <th>Okres przechowywania danych</th>
                  <th>
                    W przypadku zbierania danych w sposób inny niż od Państwa -
                    źródło ich pochodzenia i kategorie danych osobowych
                  </th>
                </tr>
                <tr>
                  <td>
                    W celu podjęcia czynności niezbędnych do zawarcia umowy, na
                    Państwa żądanie (art. 6 ust. 1 lit. b RODO),
                    <br></br>
                    <br></br>w zakresie wynikającym z przepisów prawa, w
                    szczególności Kodeksu Pracy – na podstawie art. 6 ust. 1
                    lit. c RODO, w celu realizacji procesu rekrutacyjnego,
                    <br></br>
                    <br></br>w razie udostępnienia przez Kandydata innych danych
                    niż wymagane przepisami prawa – na podstawie jego zgody, w
                    celu realizacji procesu rekrutacyjnego (art. 6 ust. 1 lit. a
                    RODO, a w razie udostepnienia danych szczególnej kategorii,
                    także art. 9 ust. 2 lit. a RODO),
                    <br></br>
                    <br></br>w celu realizacji prawnie uzasadnionego interesu
                    Administratora polegającego na ustaleniu, dochodzeniu lub
                    obronie przed roszczeniami (art. 6 ust. 1 lit. f RODO, a w
                    razie udostepnienia danych szczególnej kategorii, także art.
                    9 ust. 2 lit. f RODO),
                    <br></br>
                    <br></br>w przypadku wyrażenia dodatkowej zgody - na
                    potrzeby uwzględnienia przy przyszłych rekrutacjach (art. 6
                    ust. 1 lit. a RODO).
                    <br></br>
                    <br></br>w przypadku wyrażenia dodatkowej zgody - do
                    kontaktu z osobami wskazanymi przez kandydata jako
                    udzielające referencji (art. 6 ust. 1 lit. a RODO),
                    <br></br>
                    <br></br>UWAGA: udostępnienie przez kandydata informacji
                    przekraczających minimalny zakres danych regulowany prawem
                    pracy to wyraźne działanie równoznaczne z wyrażeniem zgody
                    na przetwarzanie tych danych w celu przeprowadzenia
                    rekrutacji na podstawie art. 6 ust. 1 lit. a RODO. Ich
                    podanie nie ma wpływu na możliwość udziału w rekrutacji.
                    <br></br>
                    <br></br>Zgodnie z art. 221 Kodeksu pracy: „§ 1. Pracodawca
                    żąda od osoby ubiegającej się o zatrudnienie podania danych
                    osobowych obejmujących: 1) imię (imiona) i nazwisko; 2) datę
                    urodzenia; 3) dane kontaktowe wskazane przez taką osobę; 4)
                    wykształcenie; 5) kwalifikacje zawodowe; 6) przebieg
                    dotychczasowego zatrudnienia. § 2. Pracodawca żąda podania
                    danych osobowych, o których mowa w § 1 pkt 4-6, gdy jest to
                    niezbędne do wykonywania pracy określonego rodzaju lub na
                    określonym stanowisku.” Jest to zakres danych osobowych
                    wymaganych domyślnie – obowiązek podania innych danych może
                    wynikać ze szczegółowych przepisów prawa.
                  </td>
                  <td>
                    Państwa dane osobowe będą przetwarzane przez 3 miesiące, a w
                    razie wyrażenia zgody na udział w dalszych rekrutacjach –
                    przez czas, na który została wyrażona zgoda, lub do czasu
                    jej wycofania.
                    <br></br>
                    <br></br>W razie uzasadnionej informacji o możliwości
                    wystąpienia roszczeń, dane osobowe będą także przechowywane
                    do upływu terminów ich przedawnienia.
                  </td>
                  <td>
                    Co do zasady, Państwa dane osobowe nie są pozyskiwane w
                    sposób inny niż od Państwa. Wyjątkowo, w przypadku Państwa
                    uprzedniej zgody, dane osobowe zostały nam przekazane przez
                    podmioty specjalizujące się w rekrutacji, w ramach systemu
                    poleceń lub przez osoby udzielające referencji. Kategorie
                    pozyskanych danych osobowych to imię, nazwisko, dane
                    kontaktowe, informacje o wykształceniu, historia
                    zatrudnienia oraz inne dane podane przez Państwa w
                    dokumentach aplikacyjnych, zaś w przypadku osób
                    udzielających referencji są to typowo dane o osiągnięciach
                    kandydata u poprzedniego pracodawcy.
                  </td>
                </tr>
              </table>
            </div>
            <li>Udzielający referencji</li>
            <div>
              <table>
                <tr>
                  <th>Cel przetwarzania i podstawa prawna</th>
                  <th>Okres przechowywania danych</th>
                  <th>
                    W przypadku zbierania danych w sposób inny niż od Państwa -
                    źródło ich pochodzenia i kategorie danych osobowych
                  </th>
                </tr>
                <tr>
                  <td>
                    W celu weryfikacji kwalifikacji kandydata na dane stanowisko
                    oraz prawidłowości podanych przez niego danych osobowych –
                    stanowi to uzasadniony interes
                  </td>
                  <td>
                    Państwa dane osobowe będą przetwarzane nie dłużej niż przez
                    okres przechowywania dokumentów aplikacyjnych kandydata.
                  </td>
                  <td>
                    Dotyczące Państwa dane osobowe zostały pozyskane od
                    kandydata do pracy, który wskazał Panią/Pana jako osobę
                    udzielającą referencji. Państwa dane osobowe są przetwarzane
                    w następującym zakresie: imię, nazwisko, stanowisko, adres
                    e-mail lub telefon służbowy.
                  </td>
                </tr>
              </table>
            </div>

            <li>Pracownicy oraz współpracownicy</li>
            <div>
              <table>
                <tr>
                  <th>Cel przetwarzania i podstawa prawna</th>
                  <th>Okres przechowywania danych</th>
                  <th>
                    W przypadku zbierania danych w sposób inny niż od Państwa -
                    źródło ich pochodzenia i kategorie danych osobowych
                  </th>
                </tr>
                <tr>
                  <td>
                    W celu realizacji umowy o pracę (art. 6 ust. 1 lit. b RODO),
                    <br></br>
                    <br></br>w celu prowadzenia rozliczeń, księgowości i
                    sprawozdawczości finansowej (art. 6 ust. 1 lit. c i f RODO),
                    <br></br>
                    <br></br>w celu realizacji Państwa uprawnień w dziedzinie
                    prawa pracy, zabezpieczenia społecznego i ochrony socjalnej
                    (art. 6 ust. 1 lit. c i art. 9 ust. 2 lit. b RODO),
                    <br></br>
                    <br></br>w celu realizacji obowiązków Administratora
                    wynikających z przepisów prawa – w szczególności prawa
                    pracy, przez co rozumiemy także wykonywanie obowiązków
                    względem ZUS, US czy NFZ, obowiązków z zakresu BHP,
                    rozliczanie wszelkich należności, archiwizowanie akt
                    osobowych (art. 6 ust. 1 lit. c i art. 9 ust. 2 lit. b
                    RODO),
                    <br></br>
                    <br></br>w celach wskazanych w treści zgód na przetwarzanie
                    danych osobowych - jeśli takie zgody były wyrażane (art. 6
                    ust. 1 lit. a RODO, art. 9 ust. 2 lit. a RODO).
                    <br></br>
                    <br></br>A także w związku z realizacją uzasadnionych
                    interesów administratora (art. 6 ust. 1 lit. f RODO):
                    <br></br>
                    <br></br>w celu zapewnienia organizacji pracy umożliwiającej
                    pełne wykorzystanie czasu pracy, kontroli produkcji oraz
                    właściwego użytkowania udostępnionych pracownikowi narzędzi
                    pracy, w tym samochodów służbowych,
                    <br></br>
                    <br></br>w celu zapewnienia bezpieczeństwa osób znajdujących
                    się na terenie organizacji oraz ochrony mienia (w tym
                    poprzez stosowanie monitoringu wizyjnego, środków kontroli
                    dostępu oraz monitoringu poczty elektronicznej),
                    <br></br>
                    <br></br>w celu organizacji wydarzeń i inicjatyw społecznych
                    – w przypadku wyrażenia chęci udziału,
                    <br></br>
                    <br></br>w celu ustalenia, dochodzenia i obrony roszczeń,
                    <br></br>
                    <br></br>w celu prowadzenia marketingu produktów i usług
                    Administratora i jego partnerów – jeśli wynika to ze
                    stosunku prawnego z pracownikiem, np. z treści zezwolenia na
                    przetwarzanie danych w postaci wizerunku udzielonego na
                    podstawie prawa autorskiego,
                    <br></br>
                    <br></br>w celach statystycznych, związanych z poprawą
                    efektywności pracy, jakości świadczonych usług i
                    dostosowaniem ich do odbiorców.
                  </td>
                  <td>
                    Państwa dane będą przechowywane:
                    <br></br>
                    <br></br>przez okres 10 lat od końca roku kalendarzowego, w
                    którym ustał/został rozwiązany stosunek pracy, gdy został on
                    nawiązany nie wcześniej niż dnia 01.01.2019 r.,
                    <br></br>
                    <br></br>przez okres 50 lat od końca roku kalendarzowego, w
                    którym ustał/został rozwiązany stosunek pracy, gdy został on
                    nawiązany wcześniej niż dnia 01.01.2019 r.
                    <br></br>
                    <br></br>w przypadku zawarcia umowy o współpracę/zlecenie/o
                    dzieło – do czasu upływu terminu przedawnienia roszczeń
                    wynikających z umowy lub wygaśnięcia obowiązków
                    przechowywania danych wynikających z przepisów prawa, w
                    szczególności przechowywania dokumentów księgowych,
                    <br></br>
                    <br></br>do dnia wniesienia uzasadnionego sprzeciwu lub
                    cofnięcia zgody, w sytuacji gdy podstawą przetwarzania
                    danych osobowych jest prawnie uzasadniony interes
                    administratora lub dobrowolna zgody osoby, której dane
                    dotyczą,
                    <br></br>
                    <br></br>nagrania z monitoringu wizyjnego, będą
                    przechowywane przez okres do trzech miesięcy, zaś jeśli
                    będzie to niezbędne do ustalenia, dochodzenia lub obrony
                    roszczeń – do czasu ostatecznego zaspokojenia dochodzonych
                    roszczeń lub upływu terminu ich przedawnienia.
                  </td>
                  <td>
                    Co do zasady, przetwarzamy dane podawane przez Państwa.
                  </td>
                </tr>
              </table>
            </div>

            <li>Współpracownicy</li>
            <div>
              <table>
                <tr>
                  <th>Cel przetwarzania i podstawa prawna</th>
                  <th>Okres przechowywania danych</th>
                  <th>
                    W przypadku zbierania danych w sposób inny niż od Państwa -
                    źródło ich pochodzenia i kategorie danych osobowych
                  </th>
                </tr>
                <tr>
                  <td>
                    W celu realizacji umowy łączącej Panią/Pana z
                    Administratorem (art. 6 ust. 1 lit. b RODO),
                    <br></br>
                    <br></br>w celu prowadzenia rozliczeń, księgowości i
                    sprawozdawczości finansowej (art. 6 ust. 1 lit. c i f RODO),
                    <br></br>
                    <br></br>w celu realizacji pozostałych obowiązków
                    wynikających z przepisów prawa (art. 6 ust. 1 lit. c oraz
                    art. 9 ust. 2 lit. b RODO),
                    <br></br>
                    <br></br>w celach wskazanych w treści zgód na przetwarzanie
                    danych osobowych - jeśli takie zgody były wyrażane (art. 6
                    ust. 1 lit. a RODO, a w przypadku danych szczególnej
                    kategorii, także art. 9 ust. 2 lit. a RODO).
                    <br></br>
                    <br></br>A także w związku z realizacją uzasadnionych
                    interesów administratora (art. 6 ust. 1 lit. f RODO):
                    <br></br>
                    <br></br>w celu zapewnienia organizacji współpracy
                    umożliwiającej pełne wykorzystanie czasu pracy, kontroli
                    produkcji oraz właściwego użytkowania udostępnionych
                    narzędzi pracy, w tym samochodów służbowych,
                    <br></br>
                    <br></br>w celu zapewnienia bezpieczeństwa osób znajdujących
                    się na terenie organizacji oraz ochrony mienia,
                    <br></br>
                    <br></br>w celu organizacji wydarzeń i inicjatyw społecznych
                    – w przypadku wyrażenia chęci udziału,
                    <br></br>
                    <br></br>w celu realizacji umów z klientami, kontrahentami i
                    dostawcami organizacji,
                    <br></br>
                    <br></br>w celu ustalenia, dochodzenia i obrony roszczeń,
                    <br></br>
                    <br></br>w celu prowadzenia marketingu produktów i usług
                    administratora i jego partnerów – jeśli wynika to ze
                    stosunku prawnego ze współpracownikiem, np. z treści
                    zezwolenia udzielonego na podstawie prawa autorskiego.
                    <br></br>
                    <br></br>w celach statystycznych, związanych z poprawą
                    efektywności pracy, jakości świadczonych usług i
                    dostosowaniem ich do odbiorców.
                  </td>
                  <td>
                    Państwa dane osobowe będą przechowywane przez do czasu
                    upływu terminów przedawnienia roszczeń wynikających z
                    łączącej Panią/Pana z Administratorem umowy.
                  </td>
                  <td>
                    Co do zasady, przetwarzamy dane podawane przez Państwa.
                  </td>
                </tr>
              </table>
            </div>

            <li>
              Członkowie rodzin i osoby towarzyszące pracowników i
              współpracowników Administratora
            </li>
            <div>
              <table>
                <tr>
                  <th>Cel przetwarzania i podstawa prawna</th>
                  <th>Okres przechowywania danych</th>
                  <th>
                    W przypadku zbierania danych w sposób inny niż od Państwa -
                    źródło ich pochodzenia i kategorie danych osobowych
                  </th>
                </tr>
                <tr>
                  <td>
                    W celu umożliwienia Państwu udziału w korzyściach
                    socjalnych, tzn. zgłoszenia i potwierdzenia Państwa
                    uprawnień do danej korzyści socjalnej (art. 6 ust. 1 lit. c
                    oraz art. 9 ust. 2 lit. b RODO),
                    <br></br>
                    <br></br>w celu realizacji Państwa uprawnień w dziedzinie
                    prawa pracy, zabezpieczenia społecznego i ochrony socjalnej,
                    oraz wykonania powiązanych obowiązków prawnych ciążących na
                    Administratorze (art. 6 ust. 1 lit. c oraz art. 9 ust. 2
                    lit. b RODO),
                    <br></br>
                    <br></br>w razie wskazania Pani/Pana jako osoby do kontaktu
                    w razie wypadku – w celu poinformowania Państwa o nagłej
                    sytuacji (art. 6 ust. 1 lit. f RODO),
                    <br></br>
                    <br></br>w celu prowadzenia rozliczeń, księgowości i
                    sprawozdawczości finansowej (art. 6 ust. 1 lit. c i f RODO),
                    <br></br>
                    <br></br>w celu realizacji innych obowiązków wynikających z
                    przepisów prawa (art. 6 ust. 1 lit. c RODO),
                    <br></br>
                    <br></br>w celach wskazanych w treści zgód na przetwarzanie
                    danych osobowych - jeśli takie zgody były wyrażane (art. 6
                    ust. 1 lit a RODO, art. 9 ust. 2 lit a RODO).
                    <br></br>
                    <br></br>A także w związku z realizacją pozostałych
                    uzasadnionych interesów administratora (art. 6 ust. 1 lit f
                    RODO):
                    <br></br>
                    <br></br>w celu ustalenia, dochodzenia i obrony roszczeń,
                    <br></br>
                    <br></br>w celach statystycznych, związanych z poprawą
                    efektywności pracy, jakości świadczonych usług i
                    dostosowaniem ich do odbiorców.
                  </td>
                  <td>
                    Państwa dane osobowe będą przechowywane przez okres
                    niezbędny dla realizacji celu, dla którego zostały zebrane,
                    do czasu wycofania zgody (w razie jej wyrażenia), przez
                    okres wymagany przepisami prawa lub do czasu upływu terminów
                    przedawnienia roszczeń – w zależności, który ze wskazanych
                    okresów jest dłuższy.
                  </td>
                  <td>
                    Jeśli Państwa dane osobowe nie zostały pozyskane
                    bezpośrednio od Państwa, udostępnił je nam nasz pracownik
                    lub współpracownik. Pana/Pani dane osobowe są przetwarzane w
                    zakresie odpowiadającym celom wskazanym powyżej. Typowe dane
                    wymagane dla realizacji tych celów to imię, nazwisko, data
                    urodzenia, adres oraz numer PESEL. W przypadku ubezpieczeń
                    społecznych, wymagany jest szerszy zakres danych: NIP, seria
                    i numer dokumentu, płeć, obywatelstwo, nazwisko rodowe,
                    okres pracy w szczególnych warunkach lub szczególnym
                    charakterze, kod stopnia pokrewieństwa/powinowactwa, kod
                    stopnia niepełnosprawności.
                  </td>
                </tr>
              </table>
            </div>

            <li>Korespondujący</li>
            <div>
              <table>
                <tr>
                  <th>Cel przetwarzania i podstawa prawna</th>
                  <th>Okres przechowywania danych</th>
                  <th>
                    W przypadku zbierania danych w sposób inny niż od Państwa -
                    źródło ich pochodzenia i kategorie danych osobowych
                  </th>
                </tr>
                <tr>
                  <td>
                    W celu prowadzenia korespondencji oraz zapewnienia obiegu i
                    archiwizacji dokumentów, co stanowi prawnie uzasadniony
                    interes administratora (art. 6 ust. 1 lit. f RODO).
                    Informacje na temat przetwarzania danych zawartych w treści
                    korespondencji i powiązanych podstaw prawnych dostępne są w
                    pozostałej części niniejszej polityki, w zależności od tego,
                    czego dotyczy korespondencja.
                  </td>
                  <td>
                    Co do zasady, dane przetwarzane są przez okresy wskazane
                    przepisami prawa, a jeżeli co do określonych dokumentów nie
                    są one wskazane, przez czas kiedy ich przechowywanie mieści
                    w ramach prawnie uzasadnionego celu administratora
                    regulowanego czasem możliwego dochodzenia roszczeń. Okres
                    przechowywania danych stanowiących treść korespondencji jest
                    jednak uzależniony od celu przetwarzania danych, którego
                    dotyczy dana korespondencja (jeżeli występują inne terminy
                    usunięcia danych, to informacje na ten temat podano w
                    odrębnej klauzuli informacyjnej).
                  </td>
                  <td>
                    Co do zasady, przetwarzamy dane podawane przez Państwa.
                  </td>
                </tr>
              </table>
            </div>

            <li>Goście</li>
            <div>
              <table>
                <tr>
                  <th>Cel przetwarzania i podstawa prawna</th>
                  <th>Okres przechowywania danych</th>
                  <th>
                    W przypadku zbierania danych w sposób inny niż od Państwa -
                    źródło ich pochodzenia i kategorie danych osobowych
                  </th>
                </tr>
                <tr>
                  <td>
                    W celu zapewnienia bezpieczeństwa osób znajdujących się na
                    terenie organizacji oraz ochrony mienia (art. 6 ust. 1 lit.
                    f RODO), a także:
                    <br></br>
                    <br></br>w celu ustalenia, dochodzenia i obrony roszczeń
                    (art. 6 ust. 1 lit. f RODO);
                    <br></br>
                    <br></br>w celu realizacji umowy z Klientem lub Kontrahentem
                    (art. 6 ust. 1 lit. b RODO – jeśli jesteś stroną umowy; art.
                    6 ust. 1 lit. f RODO – jeśli jesteś osobą współpracującą z
                    nami w imieniu Klienta lub Kontrahenta);
                    <br></br>
                    <br></br>w celu realizacji obowiązków prawnych (art. 6 ust.
                    1 lit. c RODO).
                  </td>
                  <td>
                    Państwa dane osobowe, w tym nagrania z monitoringu
                    wizyjnego, będą przechowywane przez okres do trzech
                    miesięcy, zaś jeśli będzie to niezbędne do ustalenia,
                    dochodzenia lub obrony roszczeń – do czasu ostatecznego
                    zaspokojenia dochodzonych roszczeń lub upływu terminu ich
                    przedawnienia.
                  </td>
                  <td>
                    Co do zasady, przetwarzamy dane podawane przez Państwa.
                    Jeśli nie podawali nam Państwo swoich danych, to ich źródłem
                    jest osoba, która zgłosiła nam Państwa wizytę.
                  </td>
                </tr>
              </table>
            </div>
          </ol>
        </Styled.TextWrapper>
        <Styled.TextWrapper>
          <Styled.Text>
            UWAGA: Występują także sytuacje, w których nasza organizacja jest
            podmiotem przetwarzającym dane, a Administratorem – podmiot, w
            imieniu którego przetwarzamy dane. W razie występowania takiej
            sytuacji, informacje o przetwarzaniu, otrzymacie Państwo od tego
            Administratora.
          </Styled.Text>
        </Styled.TextWrapper>
        <Styled.TextWrapper>
          <Styled.Text>
            <Styled.Strong>
              Szczegółowe informacje na temat przetwarzania danych osobowych na
              stronie internetowej:
            </Styled.Strong>
          </Styled.Text>
        </Styled.TextWrapper>
        <Styled.TextWrapper>
          <Styled.Text>
            <Styled.Strong>
              <Styled.Underline>Pliki cookie</Styled.Underline>{" "}
            </Styled.Strong>{" "}
            <Styled.Underline>
              {" "}
              - Informacja na temat wykorzystywanych plików cookie znajdują się
              w oddzielnym dokumencie – Polityka cookie (
              <Styled.Link href={"/polityka-cookies"} {...events}>
                link
              </Styled.Link>
              ).{" "}
            </Styled.Underline>
          </Styled.Text>
        </Styled.TextWrapper>
        <br></br>
        <br></br>
        <Styled.TextWrapper>
          <Styled.Strong>
            <Styled.Underline>Google Analytics</Styled.Underline>
          </Styled.Strong>
        </Styled.TextWrapper>
        <Styled.TextWrapper>
          <Styled.Text>
            Ta strona internetowa korzysta z Google Analytics, usługi analizy
            internetowej udostępnianej przez Google Inc. (poniżej: Google).
            Google Analytics używa plików cookie. Informacje generowane przez
            cookie na temat korzystania z tej strony są zazwyczaj przesyłane na
            serwer Google w USA i tam przechowywane. Jednak ze względu na
            aktywację anonimizacji IP na tych stronach, Twój adres IP zostanie
            wcześniej skrócony przez Google w państwach członkowskich Unii
            Europejskiej lub w innych państwach będących stronami umowy o
            Europejskim Obszarze Gospodarczym. Tylko w wyjątkowych przypadkach
            pełny adres IP zostanie wysłany na serwer Google w USA i tam
            skrócony. W imieniu operatora tej witryny Google wykorzysta te
            informacje do oceny korzystania z witryny przez użytkownika, do
            sporządzania raportów na temat aktywności na stronie internetowej i
            do świadczenia innych usług związanych z ruchem na stronach
            internetowych i korzystaniem z Internetu, dla operatora strony.
            Adres IP udostępniony przez Google Analytics nie zostanie połączony
            z innymi danymi posiadanymi przez Google.
          </Styled.Text>
        </Styled.TextWrapper>
        <Styled.TextWrapper>
          <Styled.Text>
            Celem przetwarzania danych jest ocena wykorzystania strony
            internetowej i kompilacja raportów dotyczących działań na stronie
            internetowej. Na podstawie korzystania ze strony internetowej i
            Internetu zapewnione zostaną inne powiązane usługi. Przetwarzanie
            opiera się na uzasadnionym interesie operatora strony.
          </Styled.Text>
        </Styled.TextWrapper>
        <Styled.TextWrapper>
          <Styled.Text>
            Google Analytics zbiera dane dotyczące adresów IP, lokalizacji
            sieci, daty wizyty, systemu operacyjnego, typu przeglądarki. Możesz
            uniemożliwić przechowywanie plików cookie za pomocą odpowiedniego
            ustawienia oprogramowania przeglądarki; należy jednak pamiętać, że
            jeśli to zrobisz, możesz nie być w stanie korzystać ze wszystkich
            funkcji tej witryny w najszerszym możliwym zakresie.
          </Styled.Text>
        </Styled.TextWrapper>
        <Styled.TextWrapper>
          <Styled.Text>
            Ponadto możesz uniemożliwić gromadzenie przez Google danych
            generowanych przez pliki cookie i danych związanych z korzystaniem z
            witryny (w tym adres IP), a także przetwarzanie tych danych przez
            Google, pobierając i instalując wtyczkę przeglądarki dostępną pod
            poniższym linkiem:
            <Styled.Link
              href={"https://tools.google.com/dlpage/gaoptout?hl=pl"}
              {...events}
              target={"_blank"}
            >
              https://tools.google.com/dlpage/gaoptout?hl=pl
            </Styled.Link>
            (Browser Add On), aby wyłączyć Google Analytics.{" "}
          </Styled.Text>
        </Styled.TextWrapper>
        <Styled.TextWrapper>
          <Styled.Strong>
            <Styled.Underline>Korzystanie z Google Ads</Styled.Underline>
          </Styled.Strong>
        </Styled.TextWrapper>
        <Styled.TextWrapper>
          <Styled.Text>
            Nasza strona korzysta z Google Conversion Tracking. Jeśli dotarłeś
            do naszej strony za pośrednictwem reklamy wysłanej przez Google,
            Google Ads ustawi plik cookie na Twoim komputerze. Śledzący plik
            cookie jest wykorzystywany, gdy użytkownik kliknie reklamę
            obsługiwaną przez Google. Przedmiotowe pliki cookie tracą ważność po
            30 dniach i nie są wykorzystywane do identyfikacji osobistej. Jeśli
            użytkownik odwiedzi niektóre strony w naszej witrynie i plik cookie
            nie wygasł, my i Google możemy rozpoznać, że użytkownik kliknął
            reklamę i został przekierowany na tę stronę. Każdy klient Google Ads
            otrzymuje inny plik cookie. Pliki cookie nie mogą być śledzone przez
            witryny reklamodawców Ads. Informacje zebrane przy użyciu pliku
            cookie do przekierowania służą do generowania statystyk przekierowań
            dla reklamodawców Ads, którzy wykorzystują taką funkcjonalność.
            Klienci są informowani jedynie o całkowitej liczbie użytkowników,
            którzy kliknęli reklamę i zostali przekierowani na stronę. Nie
            otrzymują jednak informacji, które identyfikują użytkowników.
          </Styled.Text>
        </Styled.TextWrapper>
        <Styled.TextWrapper>
          <Styled.Text>
            Jeśli nie chcesz, aby weryfikowano Twój ruch na stronie, zmień
            ustawienia przeglądarki np. poprzez blokowanie plików cookie z
            domeny „googleleadservices.com”.{" "}
          </Styled.Text>
        </Styled.TextWrapper>
        <Styled.TextWrapper>
          <Styled.Text>
            Pamiętaj, że nie możesz zrezygnować z wykorzystania plików cookie,
            jeśli chcesz nadal rejestrować dane pomiarowe. Jeśli usunąłeś
            wszystkie pliki cookie w przeglądarce, musisz ponownie ustawić
            odpowiedni plik cookie.
          </Styled.Text>
        </Styled.TextWrapper>
        <Styled.TextWrapper>
          <Styled.Strong>
            <Styled.Underline>Korzystanie z Google reCaptcha</Styled.Underline>
          </Styled.Strong>
        </Styled.TextWrapper>
        <Styled.TextWrapper>
          <Styled.Text>
            W celu zapewnienia bezpieczeństwa danych przy wykorzystywaniu
            formularzy korzystamy w niektórych przypadkach z usługi reCAPTCHA
            firmy Google Inc. Jest to szczególnie przydatne do rozróżnienia czy
            wpis jest dokonywany przez osobę fizyczną, czy następuje nadużycie
            polegające na „podszywaniu się” odpowiedniego oprogramowania lub
            maszyny pod osobę fizyczną. Jeśli jednak na tej stronie zostanie
            aktywowana anonimizacja IP, Twój adres IP zostanie wcześniej
            skrócony przez Google w państwach członkowskich Unii Europejskiej
            lub innych stronach Porozumienia o Europejskim Obszarze
            Gospodarczym. Tylko w wyjątkowych przypadkach pełny adres IP
            zostanie wysłany na serwer Google w USA i tam skrócony. Usługa
            obejmuje wysyłanie adresu IP i wszelkich innych danych wymaganych
            przez Google do usługi reCAPTCHA. Więcej informacji na temat
            Polityki prywatności Google Inc. można znaleźć{" "}
            <Styled.Link
              href={"https://policies.google.com/privacy?hl=pl&gl=de"}
              {...events}
              target={"_blank"}
            >
              tutaj
            </Styled.Link>{" "}
          </Styled.Text>
        </Styled.TextWrapper>
        <Styled.TextWrapper>
          <Styled.Strong>
            <Styled.Underline>
              Korzystanie z wtyczek społecznościowych Facebooka
            </Styled.Underline>
          </Styled.Strong>
        </Styled.TextWrapper>
        <Styled.TextWrapper>
          <Styled.Text>
            W oparciu o nasze uzasadnione interesy (tj. zainteresowanie analizą,
            optymalizacją i ekonomicznym działaniem naszej oferty online, w
            rozumieniu art. 6 ust. 1 lit. f RODO) korzystamy z wtyczek
            społecznościowych („wtyczek”) sieci społecznościowej facebook.com,
            obsługiwanych przez Facebook Ireland Ltd., 4 Grand Canal Square,
            Grand Canal Harbour, Dublin 2, Irlandia („Facebook”). Wtyczki mogą
            reprezentować elementy interakcji lub treści (np. filmy, grafiki lub
            wkłady tekstowe) i mogą być oznaczone np. przez logo Facebooka
            (białe „f” na niebieskim kafelku), przycisk „Lubię to”, lub kciuk w
            górę, lub też są oznaczone dodatkiem „Wtyczka społecznościowa
            Facebooka”. Listę i wygląd wtyczek społecznościowych Facebook można
            obejrzeć{" "}
            <Styled.Link
              href={"https://developers.facebook.com/docs/plugins/"}
              {...events}
              target={"_blank"}
            >
              tutaj
            </Styled.Link>{" "}
          </Styled.Text>
        </Styled.TextWrapper>
        <Styled.TextWrapper>
          <Styled.Text>
            Gdy użytkownik wykorzystuje funkcje na stronie internetowej, która
            zawiera taką wtyczkę, urządzenie ustanawia bezpośrednie połączenie z
            serwerami Facebook. Zawartość wtyczki jest przesyłana przez Facebook
            bezpośrednio do urządzenia użytkownika i włączana przez niego do
            oferty online. W tym procesie profile użytkowników mogą być tworzone
            z przetworzonych danych. Nie mamy wpływu na ilość danych
            gromadzonych przez Facebooka za pomocą wtyczki, stąd informujemy
            użytkowników o sposobie ich działania zgodnie z naszą wiedzą.{" "}
          </Styled.Text>
        </Styled.TextWrapper>
        <Styled.TextWrapper>
          <Styled.Text>
            Poprzez integrację wtyczek Facebook otrzymuje informacje, że
            użytkownik uzyskał dostęp do odpowiedniej strony. Jeśli użytkownik
            jest zalogowany na Facebooku, Facebook może przypisać wizytę do jego
            konta na Facebooku. Jeśli użytkownicy wchodzą w interakcję z
            wtyczkami, na przykład poprzez wykorzystanie przycisku „Lubię to”
            lub funkcję umożliwiającą pozostawienie komentarza, informacje są
            przesyłane z urządzenia bezpośrednio do Facebooka i tam
            przechowywane. Jeśli użytkownik nie jest członkiem Facebooka, nadal
            istnieje możliwość, że Facebook pozyska adres IP.{" "}
          </Styled.Text>
        </Styled.TextWrapper>
        <Styled.TextWrapper>
          <Styled.Text>
            Cel i zakres zbierania danych oraz dalsze przetwarzanie i
            wykorzystywanie danych przez Facebook, a także związane z nimi prawa
            i opcje ustawiania ochrony prywatności użytkowników, można znaleźć{" "}
            <Styled.Link
              href={"https://www.facebook.com/about/privacy/"}
              {...events}
              target={"_blank"}
            >
              w polityce prywatności Facebooka.
            </Styled.Link>{" "}
          </Styled.Text>
        </Styled.TextWrapper>
        <Styled.TextWrapper>
          <Styled.Text>
            Jeśli użytkownik jest członkiem Facebooka i nie chce, aby Facebook
            zbierał dane o nim za pośrednictwem niniejszej strony internetowej i
            łączył je z danymi użytkownika już przechowywanymi na Facebooku,
            musi on wylogować się z Facebooka i usunąć pliki cookie przed
            skorzystaniem z naszej strony internetowej. Inne zmiany ustawień
            dotyczące wykorzystania danych do celów reklamowych są możliwe w{" "}
            <Styled.Link
              href={
                "https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen"
              }
              {...events}
              target={"_blank"}
            >
              w ustawieniach profilu
            </Styled.Link>{" "}
            na Facebooku. Ustawienia są przystosowane do wszystkich urządzeń,
            takich jak komputery stacjonarne lub urządzenia mobilne.
          </Styled.Text>
        </Styled.TextWrapper>
        <Styled.TextWrapper>
          <Styled.Strong>
            <Styled.Underline>
              Pomiar zmian za pomocą piksela Facebooka
            </Styled.Underline>
          </Styled.Strong>
        </Styled.TextWrapper>
        <Styled.TextWrapper>
          <Styled.Text>
            Za Twoją zgodą używamy „piksela akcji użytkownika” zwanego „pikselem
            Facebooka”, tj. usługi dostarczanej przez Meta Platforms, Inc.,
            Meta, 1 Hacker Way, Menlo Park, CA 94025, USA („Facebook”) na naszej
            stronie internetowej. Z jej pomocą możemy śledzić działania
            użytkowników po obejrzeniu lub kliknięciu przez nich reklamy na
            Facebooku. Pozwala nam to śledzić skuteczność reklamy na Facebooku,
            co wykorzystujemy do celów statystycznych i badań rynku. Dane
            zebrane w ten sposób są dla nas anonimowe, tzn. nie widzimy danych
            osobowych poszczególnych użytkowników. Dane te są jednak
            przechowywane i przetwarzane przez Facebook, o czym informujemy
            zgodnie z naszą wiedzą. Facebook może łączyć te dane z kontem na
            Facebooku, a także wykorzystywać do własnych celów reklamowych,
            zgodnie z{" "}
            <Styled.Link
              href={"https://www.facebook.com/about/privacy/"}
              {...events}
              target={"_blank"}
            >
              polityką prywatności
            </Styled.Link>{" "}
            Facebooka. Możesz uniemożliwić Facebookowi i jego podmiotom
            stowarzyszonym możliwość wyświetlania reklam na Facebooku i poza
            nim.
          </Styled.Text>
        </Styled.TextWrapper>
        <Styled.TextWrapper>
          <Styled.Text>
            Zgoda ta może być wyrażona wyłącznie przez użytkowników w wieku
            powyżej 13 lat. Jeśli jesteś młodszy, prosimy o zwrócenie się do
            opiekunów o jej wyrażenie.{" "}
          </Styled.Text>
        </Styled.TextWrapper>
        <Styled.TextWrapper>
          <Styled.Text>
            Jeśli chcesz dowiedzieć się więcej o tym, jak Facebook gwarantuje
            zgodność z europejskimi przepisami dotyczącymi ochrony danych,
            kliknij{" "}
            <Styled.Link
              {...events}
              href={"https://www.facebook.com/about/privacy/"}
              target={"_blank"}
            >
              tutaj.
            </Styled.Link>{" "}
          </Styled.Text>
        </Styled.TextWrapper>
        <Styled.TextWrapper>
          <Styled.Strong>
            <Styled.Underline>Logi serwera</Styled.Underline>
          </Styled.Strong>
        </Styled.TextWrapper>
        <Styled.TextWrapper>
          <ul>
            <li>
              Korzystanie ze Strony wiąże się z przesyłaniem zapytań do serwera,
              na którym przechowywana jest Strona.
            </li>
            <li>
              Każde zapytanie skierowane do serwera zapisywane jest w logach
              serwera. Logi obejmują m.in. adres IP Użytkownika, datę i czas
              serwera, informacje o przeglądarce internetowej i systemie
              operacyjnym, z jakiego korzysta Użytkownik.
            </li>
            <li>Logi zapisywane i przechowywane są na serwerze.</li>
            <li>
              Dane zapisane w logach serwera nie są kojarzone z konkretnymi
              osobami korzystającymi ze Strony i nie są wykorzystywane przez
              Administratora w celu identyfikacji Użytkownika.{" "}
            </li>
            <li>
              Logi serwera stanowią wyłącznie materiał pomocniczy służący do
              administrowania Stroną, a ich zawartość nie jest ujawniana nikomu
              poza osobami upoważnionymi do administrowania serwerem.
            </li>
          </ul>
        </Styled.TextWrapper>

        <Styled.Title>ODBIORCY DANYCH</Styled.Title>
        <Styled.TextWrapper>
          <Styled.Text>
            W związku z prowadzoną działalnością, Państwa dane osobowe mogą być
            ujawniane następującym podmiotom:
          </Styled.Text>
        </Styled.TextWrapper>
        <Styled.TextWrapper>
          <ol style={{ listStyle: "lower-latin" }}>
            <li>
              organom państwowym lub innym podmiotom uprawnionym na podstawie
              przepisów – jeśli jest to niezbędne do realizacji obowiązków
              prawnych,
            </li>
            <li>
              podmiotom wspierającym nas w prowadzonej działalności na nasze
              zlecenie, w szczególności: dostawcom zewnętrznych systemów
              teleinformatycznych wspierającym naszą działalność, podwykonawcom,
              podmiotom audytującym naszą działalność lub rzeczoznawcom, przy
              czym takie podmioty będą przetwarzać dane na podstawie umowy z
              Administratorem i wyłącznie zgodnie z jego poleceniami,
            </li>
            <li>
              podmiotom świadczącym usługi księgowe, kadrowe lub prawne – w
              zakresie niezbędnym do zapewnienia realizacji obowiązków prawnych
              lub ustalenia, dochodzenia i obrony roszczeń,
            </li>
            <li>
              firmom utylizującym lub archiwizującym dokumenty i inne nośniki –
              w zakresie, w jakim dane przechowywane są w wersji papierowej lub
              na tych nośnikach,
            </li>
            <li>
              firmom świadczącym usługi kurierskie i pocztowe – w przypadku
              prowadzenia korespondencji,{" "}
            </li>
            <li>
              osobom udzielającym referencji – jeśli zostały wskazane przez
              kandydata,
            </li>
            <li>
              placówkom medycznym – w razie skierowania na badania medycyny
              pracy lub wystąpienia innych okoliczności wymagających ich
              udziału,
            </li>
            <li>
              bankom, zakładom ubezpieczeń oraz pozostałym instytucjom
              finansowym i płatniczym - w przypadku konieczności prowadzenia
              rozliczeń,
            </li>
            <li>
              firmom świadczącym usługi: ochroniarskie, kontroli dostępu i
              monitorujące wykorzystanie narzędzi pracy, odnośnie pracowników i
              współpracowników Administratora.
            </li>
            <li>
              firmom prowadzącym działania marketingowe, w razie wyrażenia
              stosownej zgody,
            </li>
            <li>
              firmom przeprowadzającym szkolenia, w przypadku udziału w takim
              szkoleniu,{" "}
            </li>
            <li>
              hotelom i firmom transportowym, w przypadku podróży służbowych.{" "}
            </li>
          </ol>
        </Styled.TextWrapper>

        <Styled.Title>
          UPRAWNIENIA W ZAKRESIE PRZETWARZANYCH DANYCH
        </Styled.Title>
        <Styled.TextWrapper>
          <Styled.Text>
            Każdej osobie, której dane są przetwarzane przez Administratora,
            przysługuje prawo do:
          </Styled.Text>
        </Styled.TextWrapper>
        <Styled.TextWrapper>
          <ol style={{ listStyle: "lower-latin" }}>
            <li>dostępu do swoich danych osobowych, </li>
            <li>sprostowania swoich danych osobowych, </li>
            <li>usunięcia swoich danych osobowych, </li>
            <li>ograniczenia przetwarzania swoich danych osobowych,</li>
            <li>
              wniesienia sprzeciwu wobec przetwarzania swoich danych osobowych
              (zgodnie z art. 21 ust. 1 RODO, składając sprzeciw, należy wskazać
              jego przyczyny związane z Państwa szczególną sytuacją),{" "}
            </li>
            <li>przenoszenia swoich danych osobowych.</li>
          </ol>
        </Styled.TextWrapper>
        <Styled.TextWrapper>
          <Styled.Text>
            Więcej informacji o prawach osób, których dane dotyczą, dostępnych
            jest w treści art. 12-23 RODO, którego tekst można znaleźć pod
            adresem:{" "}
            <Styled.Underline>
              <Styled.Link
                href={
                  "https://eur-lex.europa.eu/legal-content/PL/TXT/?uri=CELEX%3A32016R0679"
                }
                {...events}
                target={"_blank"}
              >
                https://eur-lex.europa.eu/legal-content/PL/TXT/?uri=CELEX%3A32016R0679
              </Styled.Link>
            </Styled.Underline>
          </Styled.Text>
        </Styled.TextWrapper>
        <Styled.TextWrapper>
          <Styled.Text>
            Nadto przysługuje Państwu prawo wniesienia skargi do organu
            nadzorczego, tj. Prezesa Urzędu Ochrony Danych Osobowych, ul. Stawki
            2, 00-193 Warszawa, więcej informacji pod adresem:{" "}
            <Styled.Underline>
              <Styled.Link
                href={"https://uodo.gov.pl/pl/p/skargi"}
                {...events}
                target={"_blank"}
              >
                https://uodo.gov.pl/pl/p/skargi
              </Styled.Link>
            </Styled.Underline>
          </Styled.Text>
        </Styled.TextWrapper>

        <Styled.Title>DOBROWOLNOŚĆ PODANIA DANYCH</Styled.Title>
        <Styled.TextWrapper>
          <Styled.Text>
            Podanie danych jest niezbędne do zawarcia umów i rozliczenia
            prowadzonej działalności oraz wywiązania się przez Administratora z
            wymogów prawa. W pozostałym zakresie (w szczególności w celu
            przetwarzania przez Administratora danych w celach marketingowych)
            podanie danych jest dobrowolne.
          </Styled.Text>
        </Styled.TextWrapper>
        <Styled.Title>
          PRZEKAZYWANIE DANYCH DO PAŃSTW TRZECICH LUB ORGANIZACJI
          MIĘDZYNARODOWYCH
        </Styled.Title>
        <Styled.TextWrapper>
          <Styled.Text>
            Dane osobowe co do zasady nie będą przekazywane poza Europejski
            Obszar Gospodarczy (dalej: „EOG”). Mając jednak na uwadze
            świadczenie usług przez naszych podwykonawców przy realizacji
            wsparcia dla usług teleinformatycznych oraz infrastruktury IT,
            Administrator może zlecać wykonywanie określonych czynności bądź
            zadań informatycznych uznanym podwykonawcom działającym poza EOG, co
            może powodować przekazanie Państwa danych poza obszar EOG. Zgodnie z
            decyzją Komisji Europejskiej państwa odbiorców spoza EOG zapewniają
            odpowiedni stopień ochrony danych osobowych zgodny ze standardami
            EOG.
          </Styled.Text>
        </Styled.TextWrapper>
        <Styled.TextWrapper>
          <Styled.Text>
            W przypadku odbiorców na terytorium państw nieobjętych decyzją
            Komisji Europejskiej w celu zapewnienia odpowiedniego stopnia tej
            ochrony eksporter danych (administrator lub podmiot przetwarzający,
            działający na zlecenie administratora), zawiera umowy z odbiorcami
            danych osobowych, które są oparte na standardowych klauzulach
            umownych wydanych przez Komisję Europejską zgodnie z art. 46 ust. 2
            lit. c RODO.
          </Styled.Text>
        </Styled.TextWrapper>
        <Styled.TextWrapper>
          <Styled.Text>
            Kopię standardowych klauzul umownych można uzyskać od administratora
            – jego dane kontaktowe podano wyżej. Zastosowany przez
            administratora sposób zabezpieczenia Twoich danych jest zgodny z
            zasadami przewidzianymi w rozdziale V RODO. Możesz zażądać dalszych
            informacji o zabezpieczeniach stosowanych w tym zakresie, uzyskać
            kopię tych zabezpieczeń oraz dowiedzieć się, w jakich w miejscach są
            udostępniane.
          </Styled.Text>{" "}
        </Styled.TextWrapper>
        <Styled.Title>
          PRZETWARZANIE DANYCH OSOBOWYCH W SPOSÓB ZAUTOMATYZOWANY
        </Styled.Title>
        <Styled.TextWrapper>
          <Styled.Text>
            Państwa dane nie będą podlegały zautomatyzowanemu podejmowaniu
            decyzji, w tym profilowaniu.
            <br></br>
            <br></br>
          </Styled.Text>
        </Styled.TextWrapper>
      </Styled.Wrapper>
    </Styled.PolicyPrivacy>
  )
}

export default PrivacyPolicy
